/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */

import moment from 'moment';
import Product from '../models/product';
import GroupedProduct from '../models/groupedProduct';
import ProductWithAmount from '../models/productWithAmount';
import OrderProduct from '../models/orderProduct';
import ProductWithLocation from '../models/productWithLocation';
import ProductLocation from '../models/productLocation';
import constants from './constants';
import User from '../models/user';

function groupProductsByCategory(array: Array<Product | ProductWithAmount>): Array<GroupedProduct> {
    array.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    array.sort((a, b) => (a.category || '').localeCompare(b.category || ''));
    array.sort((a, b) => ((a.isMainPanel === b.isMainPanel) ? 0 : a.isMainPanel ? -1 : 1));

    const groupedArray = array
        .map((product: any): GroupedProduct => ({
            category: product.category || '',
            products: array.filter(p => p.category === product.category)
        }))
        .filter((product, index, arr) => (arr.findIndex(p => p.category === product.category) >= index));

    return groupedArray;
}

function flatGroupedProductsArray(array: Array<ProductWithAmount>): Array<ProductWithAmount> {
    const flattedProducts: Array<ProductWithAmount> = [];

    array.forEach(product => {
        flattedProducts.push(
            ...new Array<ProductWithAmount>(product.amount).fill({ ...product })
        );
    });

    return flattedProducts;
}

function getListOfProductsWithLocations(array: Array<OrderProduct | ProductLocation>): Array<ProductWithLocation> {
    const allNewProducts: Array<ProductWithLocation> = [];

    array.forEach((product, categoryIndex) => {
        allNewProducts.push(
            ...new Array<ProductWithLocation>(product.amount || 0)
                .fill({ ...product.product, orderProductId: product.id, location: '', serialNo: '', isAssigned: false, categoryIndex, indexDevice: '' })
                .map((p, index) => (
                    {
                        ...p,
                        location: (product.locations
                            ? product.locations[index]?.name
                                ? product.locations[index]?.name
                                : ''
                            : ''),
                        serialNo: (product.locations
                            ? product.locations[index]?.serialNo
                                ? product.locations[index]?.serialNo
                                : ''
                            : ''),
                        isAssigned: (product.locations
                            ? product.locations[index]?.isAssigned
                                ? product.locations[index]?.isAssigned
                                : false
                            : false),
                        mainPanelId: (product.locations
                            ? product.locations[index]?.mainPanelId
                                ? product.locations[index]?.mainPanelId
                                : ''
                            : ''),
                        locationIndex: index,
                        indexDevice: (product.locations
                            ? product.locations[index]?.indexDevice
                                ? product.locations[index]?.indexDevice
                                : ''
                            : '')
                    }
                ))
        );
    });

    allNewProducts.sort((a, b) => (((a?.indexDevice || '') > (b?.indexDevice || '')) ? 1 : -1));

    return allNewProducts;
}

function isAuthenticated(user: User) {
    return user && user.id;
}

function getDate(date: string | Date | undefined, format = constants.DATE.DATE_FORMAT) {
    if(!date) {
        return '';
    }
    return moment(date).format(format);
}

function getDateTime(date: string | Date, format = constants.DATE.DATE_TIME_FORMAT) {
    return moment(date).format(format);
}

function getHTMLContent(s: any) {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
}

function parseCreditCardNumber(creditCard: string) {
    if(creditCard === 'Not assigned') {
        return creditCard;
    }
    return creditCard.replace(/x/g, '*').replace(/(.{4})/g, '$1 ').trim();
}

function displayDecimal(value: number | undefined | null) {
    return `${(value || 0).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })}`;
}

function displayCurrency(value: number | undefined | null) {
    return `$${displayDecimal(value)}`;
}

export {
    groupProductsByCategory,
    flatGroupedProductsArray,
    getListOfProductsWithLocations,
    isAuthenticated,
    getDate,
    getDateTime,
    getHTMLContent,
    parseCreditCardNumber,
    displayDecimal,
    displayCurrency
};
