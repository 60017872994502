import { Row } from 'antd';
import { RowProps } from 'antd/lib/row';

export default function CustomRow(props: RowProps): JSX.Element {
    return (
        <Row gutter={25} {...props}>
            {props.children}
        </Row>
    );
}
