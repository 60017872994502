/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableProps } from 'antd/lib/table';
import { Empty, Pagination, Table } from 'antd';
import styled from 'styled-components';

import constants from '../utils/constants';

interface ICustomTableProps extends TableProps<any> {
    columns: any;
    hidePagination?: boolean;
    // eslint-disable-next-line no-unused-vars
    onChangePage?: (page: number) => void;
}

const TableContainer = styled.div`
    .table-mobile {
        padding: 24px;
    }
`;

const MobileTable = styled.div`
    border-radius: 15px;
    padding: 15px;
    display: none;

    .table-row {
        border-top: 1px solid #ccc;
        padding: 25px 0px;

        &:first-of-type {
            border-top: none;
            margin: unset;
            padding-top: 0px;
        }

        .table-column {
            margin-top: 10px;

            &:first-child {
                margin-top: 0px;
            }
        }
    }

    a, span {
        color: ${constants.COLOR.ACCENT_COLOR};
    }

    @media screen and (max-width: 1200px) {
        display: block;
    }
`;

const DeskptopTable = styled.div`
    display: block;

    @media screen and (max-width: 1200px) {
        display: none;
    }
`;

const StyledTable = styled(Table) <ICustomTableProps>`
overflow: hidden;
color: #000;

a {
    color: ${constants.COLOR.PRIMARY};
}

.ant-btn > span {
    font-weight: normal;
}


.ant-table-pagination.ant-pagination {
    padding-right: 25px;
}

.ant-pagination-item-active {
    border-color: ${constants.COLOR.PRIMARY} !important;
}


.ant-card-body {
    padding: 0;
}

.ant-table-tbody > tr > td {
    height: 56px;
}

.ant-table-tbody > tr:nth-child(1) > td {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}

.ant-table-tbody > td:last-child {
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.ant-table-tbody > tr:nth-child(n) > td {
    background-color: #fff ;
    border: none;
}

.ant-table-tbody > tr:nth-child(2n) > td {
    background-color: #f0f0f7 !important;
    border: none;
}

.ant-table-tbody > tr:nth-child(2n) > td:nth-child(1) {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}

.ant-table-tbody > tr:nth-child(2n) > td:last-child {
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.ant-table-container table > thead > tr th {
    background-color: ${constants.COLOR.PRIMARY};
    text-align: left;
    font: normal normal normal 14px/14px Jost;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    height: 38px;
}

.ant-table-container table > thead > tr th:hover {
    background-color: ${constants.COLOR.PRIMARY};

}

.ant-table-container table > thead > tr:first-child th:nth-child(1) {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.ant-table-cell {
    padding: 12px 16px;
  
}

.ant-table-tbody td {
    text-align: left;
    font: normal normal normal 12px/12px Jost;
    letter-spacing: 0px;
    color: #23456B;
    opacity: 1;     
}
`;

const CustomTable = (props: ICustomTableProps): JSX.Element => {
    const { hidePagination = false } = props;

    return (
        <TableContainer>
            <MobileTable>
                {props.dataSource?.length === 0 && (
                    <Empty style={{ marginBottom: 25 }} />
                )}
                {props.dataSource
                    && Array.isArray(props.dataSource)
                    && props.dataSource.map((data: any, index: number) => (
                        <div className='table-row' key={index}>
                            {props.columns
                                && Array.isArray(props.columns)
                                && props.columns.map((column: any, columnIndex: number) => (
                                    <div className='table-column' key={columnIndex}>
                                        <b style={{ fontWeight: 600 }}>{column.title}</b>:
                                        <div className='table-data'>
                                            {column.render
                                                ? column.render(data[column.key] || data, data)
                                                : data[column.key]}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ))}
                <Pagination
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                    pageSize={constants.TABLE.PAGE_SIZE}
                    showSizeChanger={false}
                    onChange={(page) => props.onChangePage && props.onChangePage(page)}
                    {...props.pagination}
                />
            </MobileTable>
            <DeskptopTable>
                <StyledTable
                    onChange={props.onChange}
                    {...props}
                    pagination={{
                        pageSize: constants.TABLE.PAGE_SIZE,
                        showSizeChanger: false,
                        hideOnSinglePage: hidePagination,
                        ...props.pagination
                    }}
                />
            </DeskptopTable>
        </TableContainer>
    );
};

export default CustomTable;
