import {
    SortAscendingOutlined
} from '@ant-design/icons';
import { BasicProps } from 'antd/lib/layout/layout';

export default function CustomDownArrowElement(props: BasicProps): JSX.Element {
    return (
        <SortAscendingOutlined {...props} />
    );
}
