/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-return-assign */
import { Component } from 'react';
import Recaptcha from 'react-invisible-recaptcha';
import Form, { FormProps } from 'antd/lib/form';
import axios from 'axios';
import { CustomModal } from './index';
import { interceptPostError } from '../utils/interceptors';
import { api } from '../utils/api';

type Props = FormProps & {
    onStartedValidatingCaptcha?: () => void;
    onCaptchaValidationFailed?: () => void;
    onSubmit?: (values: any) => void;
};

type State = {
    values: any;
}

export default class ReCaptcha extends Component<Props, State> {
    source = axios.CancelToken.source();

    recaptcha: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            values: {
                name: '',
                email: '',
                message: '',
                createdAt: new Date()
            }
        };
    }

    render = (): JSX.Element => (
        <Form
            {...this.props}
            onFinish={this.onSubmit}
            style={{ width: '100%' }}
        >
            {this.props.children}

            <Recaptcha
                ref={(ref: any) => this.recaptcha = ref}
                sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
                onResolved={this.checkTokenResponse}
                onError={this.handleFailedCaptchaValidation}
                badge={'bottomleft'}
                locale="en"
            />
        </Form>
    )

    onSubmit = (values: any): void => {
        // console.log('onSubmit', 'customFormRecaptcha', values);
        this.props.onStartedValidatingCaptcha?.();

        this.recaptcha.execute(values);
        this.setState({ values });
    }

    handleFailedCaptchaValidation = () => {
        // console.log('handleFailedCaptchaValidation');
        this.props.onCaptchaValidationFailed?.();

        CustomModal.displayCustomWarning({
            title: 'Invalid captcha validation',
            content: 'Please refresh page and try one more time.'
        });
    }

    checkTokenResponse = (): void => {
        const { onSubmit } = this.props;
        if (onSubmit) onSubmit(this.state.values);
        this.recaptcha.reset();
    }

    checkTokenResponse_Old = (): void => {
        // console.log('checkTokenResponse');
        const token = this.recaptcha.getResponse();
        const { onSubmit } = this.props;

        api
            .post('public/article/comment/valid-token', {
                token
            }, {
                cancelToken: this.source.token
            })
            .then(res => res.data)
            .then(({ isValid }) => {
                if (!isValid) {
                    CustomModal.displayCustomWarning({
                        title: 'Refresh website'
                    });
                    return;
                }
                if (onSubmit && isValid) onSubmit(this.state.values);
                this.recaptcha.reset();
            })
            .catch(interceptPostError);
    }
}
