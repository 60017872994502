import Form, { FormProps } from 'antd/lib/form';
import styled from 'styled-components';

const StyledForm = styled(Form)`
    input, .ant-input-number, .ant-picker {
        width: 100%;
    }
`;

export default function CustomForm(props: FormProps): JSX.Element {
    return (
        <StyledForm layout="vertical" {...props}>
            {props.children}
        </StyledForm>
    );
}
