import { BasicProps } from 'antd/lib/layout/layout';
import styled from 'styled-components';
import constants from '../utils/constants';

const Container = styled.div`
    background-color: #fff;
    border-bottom: 2px solid ${constants.COLOR.PRIMARY};
    padding: 25px;
    margin-bottom: 25px;
    width: 100%;
`;

export default function CustomFilterContainer(props: BasicProps): JSX.Element {
    return (
        <Container {...props}>
            {props.children}
        </Container>
    );
}
