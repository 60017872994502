import { BasicProps } from 'antd/lib/layout/layout';
import styled from 'styled-components';
import constants from '../utils/constants';

const Heading = styled.h2`
    font-size: 18px;
    color: ${constants.COLOR.PRIMARY};
    margin-top: 25px;
    margin-bottom: 5px;
`;

export default function CustomHeading(props: BasicProps): JSX.Element {
    return (
        <Heading {...props}>
            {props.children}
        </Heading>
    );
}
