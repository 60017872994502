/* eslint-disable import/prefer-default-export */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { displayAlulaSessionNotEstabilishedAlert, displayServiceUnavaliableAlert, displayUnathorizedReourceAlert } from './alerts';
import constants from './constants';

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;
axios.defaults.withCredentials = true;

const api = axios.create();

api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError): AxiosError | Promise<any> | void => {
        if (!error.response) {
            return Promise.reject(new Error(''));
        }

        if (error.code === constants.RESPONSE_STATUSES.ENOTFOUND) {
            return Promise.reject(new Error(''));
        }

        if (error.code === constants.RESPONSE_STATUSES.ECONNABORTED) {
            return Promise.reject(new Error(''));
        }

        if (error.response?.status === 503) {
            displayServiceUnavaliableAlert(error?.response?.data?.service || 'Unknown');
            return Promise.reject(new Error(''));
        }

        if (error.response?.status === 401) {
            displayUnathorizedReourceAlert();
            return Promise.reject(new Error(''));
        }

        if (error.response?.status === 403) {
            displayAlulaSessionNotEstabilishedAlert();
            return Promise.reject(new Error(''));
        }

        return Promise.reject(error);
    }
);

export { api };
