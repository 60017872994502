import { useEffect } from 'react'
import Router from 'next/router'
import useSWR from 'swr'

export default function useCurrentUser({
  redirectTo = '',
  redirectIfFound = '',
} = {}) {
  const {data, mutate: mutateUser, isValidating} = useSWR('/auth/user');

  useEffect(() => {
    if(isValidating) {
      return;
    }
    if (!data?.user && redirectTo) {
      Router.push(redirectTo);
    } else if (data?.user && redirectIfFound) {
      Router.push(redirectIfFound);
    }
  }, [data, redirectIfFound, redirectTo]);

  return { user: data?.user, mutateUser };
}