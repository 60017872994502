import Button, { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';
import { darken } from 'polished';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';

import constants from '../utils/constants';

const StyledButton = styled(Button)`
    background-color: ${constants.COLOR.PRIMARY};
    border: none;

    &:hover, &:active, &:focus {
        background-color: ${darken(0.2, constants.COLOR.PRIMARY)};
    }
`;

export default function CustomGoBackPrimaryButton(props: ButtonProps): JSX.Element {
    return (
        <StyledButton {...props} type="primary">
            <ArrowLeftOutlined />
            {props.children}
        </StyledButton>
    );
}
