import { AxiosError } from 'axios';
import { message } from 'antd';
import { CustomModal } from '../custom';

export const interceptGetError = (error: AxiosError): void => {
    if (!error.response) return;

    message.error('Cannot load this resource. Please try again.');
};

export const interceptPostError = (error: AxiosError, messageText?: string | Array<string>): void => {
    if (!error.response) return;

    CustomModal.displayCustomWarning({
        title: 'An error occured',
        content:
            <div>
                An error occurred during the request. Please try again.
                {messageText
                    && <p style={{ marginTop: '2%' }}>
                        <span><i>Error Details:</i></span>
                        {Array.isArray(messageText) ? messageText.map(m => <div key={m}>{m}</div>) : <span>{messageText}</span>}
                    </p>
                }
            </div>
    });
};
