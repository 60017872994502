import { Component } from 'react';
import { Modal } from 'antd';
import { ModalFuncProps, ModalProps } from 'antd/lib/modal';
import styled from 'styled-components';

import constants from '../utils/constants';

const StyledModal = styled(Modal)``;

export default class CustomModal extends Component<ModalProps> {
    static displayCustomConfirm = (props: ModalFuncProps): void => {
        Modal.confirm({
            okButtonProps: {
                style: {
                    backgroundColor: constants.COLOR.PRIMARY,
                    border: 'none'
                }
            },
            cancelButtonProps: {
                type: 'link',
                style: {
                    color: constants.COLOR.PRIMARY
                }
            },
            width: 600,
            ...props
        });
    }

    static displayCustomWarning = (props: ModalFuncProps): void => {
        Modal.warning({
            okButtonProps: {
                style: {
                    backgroundColor: constants.COLOR.PRIMARY,
                    border: 'none'
                }
            },
            width: 600,
            ...props
        });
    }

    static displayCustomSuccess = (props: ModalFuncProps): void => {
        Modal.success({
            okButtonProps: {
                style: {
                    backgroundColor: constants.COLOR.PRIMARY,
                    border: 'none'
                }
            },
            width: 600,
            ...props
        });
    }

    render = (): JSX.Element => (
        <StyledModal
            width={1000}
            okButtonProps={{
                style: {
                    backgroundColor: constants.COLOR.PRIMARY,
                    border: 'none'
                }
            }}
            cancelButtonProps={{
                color: constants.COLOR.PRIMARY
            }}
            {...this.props}
        >
            {this.props.children}
        </StyledModal>
    )
}
