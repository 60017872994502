import { Col } from 'antd';
import { ColProps } from 'antd/lib/col';

export default function CustomRow(props: ColProps): JSX.Element {
    return (
        <Col md={8} span={24} {...props}>
            {props.children}
        </Col>
    );
}
