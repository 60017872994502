/* eslint-disable no-confusing-arrow */
import Card, { CardProps } from 'antd/lib/card';
import styled from 'styled-components';
import constants from '../utils/constants';

interface Props extends CardProps {
    $isSectionHeader?: boolean;
}

const StyledCard = styled(Card) <Props>`
    color: ${constants.COLOR.PRIMARY};
    padding: 0px;
    border: none;
    background-color: inherit;

    a {
        color: ${constants.COLOR.PRIMARY} !important;
    }

    .ant-pagination-item-active {
        border-color: ${constants.COLOR.PRIMARY} !important;
    }

    .ant-card {
        background-color: #fff;
    }

    .ant-card-head {
        border-bottom: none;
        font-size: 16px;
        padding: 0px;
        color: ${constants.COLOR.PRIMARY};
    }

    .ant-card-head-title {
        font: normal normal normal 24px/18px Jost !important;
    }

    > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
        color: ${constants.COLOR.PRIMARY};
        padding: 0px;
        font-size: ${props => props.$isSectionHeader ? '24px' : '18px'};
        font-weight: 500 !important;
        letter-spacing: 0px !important;
        color: #23456B !important;
        opacity: 1 !important;
        height: 30px;
        line-height: 30px !important;
    }

    .ant-card-body {
        padding: 0px 0px;
    }

    .ant-card-extra {
        padding: 0;
    }
`;

const CustomCard = (props: Props): JSX.Element => (
    <StyledCard {...props}>
        {props.children}
    </StyledCard>
);

export default CustomCard;
