import Button, { ButtonProps } from 'antd/lib/button';
import { darken } from 'polished';
import styled from 'styled-components';
import constants from '../utils/constants';

const StyledButton = styled(Button)`
    color: ${constants.COLOR.PRIMARY};

    &:hover, &:active, &:focus {
        color: ${darken(0.2, constants.COLOR.PRIMARY)};
    }
`;

export default function CustomRejectButton(props: ButtonProps): JSX.Element {
    return (
        <StyledButton type="link" {...props}>
            {props.children}
        </StyledButton>
    );
}
