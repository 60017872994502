import { useForm } from 'antd/lib/form/Form';
import {
    Input,
    Select
} from 'antd';
import Form, { FormProps } from 'antd/lib/form';
import {
    CustomUpArrowElement,
    CustomDownArrowElement
} from '../custom/index';
import constants from '../utils/constants';
import SortValues from '../models/SortValues';

const { Option } = Select;

type OptionData = {
    optionName: string;
    optionValue: string;
};

type Props = {
    custom?: boolean;
    options?: Array<OptionData>
    // eslint-disable-next-line no-unused-vars
    onFinish?: (values: SortValues) => void;
} & FormProps;

const CustomSort = (props: Props): JSX.Element => {
    const { onFinish, options } = props;

    const [form] = useForm();

    const showOptions = options ? options.map(option => (
        <Option
            key={option.optionValue}
            value={option.optionValue}
        >
            {option.optionName}
        </Option>
    )) : '';

    const validateMessages = {
        required: constants.MESSAGE.FIELD_IS_REQUIRED
    };

    return (
        <Form
            form={form}
            validateMessages={validateMessages}
            onFinish={onFinish}
        >
            {!props.custom
                ? <Form.Item name='sort'>
                    <Input.Group compact >
                        <Form.Item
                            name={['sort', 'sortSelect']}
                            label="Type"
                            rules={[{ required: true }]}
                        >
                            <Select
                                size='large'
                                style={{ width: 200, marginRight: 20 }}
                            >
                                {showOptions}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={['sort', 'sortType']}
                            label={
                                <CustomUpArrowElement
                                    style={{ fontSize: constants.FONT_SIZE.labelSort }}
                                />
                            }
                        >
                            <Select
                                size='large'
                                style={{ width: 70, marginRight: 20 }}
                                onChange={() => form.submit()}
                            >
                                <Option value='ASC'>
                                    <CustomUpArrowElement
                                        style={{ fontSize: constants.FONT_SIZE.labelSort }}
                                    />
                                </Option>
                                <Option value='DESC'>
                                    <CustomDownArrowElement
                                        style={{ fontSize: constants.FONT_SIZE.labelSort }}
                                    />
                                </Option>
                            </Select>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                : props.custom}
        </Form>

    );
};

export default CustomSort;
