import Button, { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';

import constants from '../utils/constants';

const StyledButton = styled(Button)`
    color: ${constants.COLOR.PRIMARY};
`;

export default function CustomLinkButton(props: ButtonProps): JSX.Element {
    return (
        <StyledButton type="link" {...props}>
            {props.children}
        </StyledButton>
    );
}
